.recharge_container{
    margin-top: 150px;
    margin-bottom: 50px;
}
.input_coupon{
    border-radius: 10px;
    color: var(--text_caption_color);
}

.css-phksla-MuiInputBase-root-MuiFilledInput-root {
    background-color: var(--white_color) !important;
    border-radius: 10px !important;
}
.Proceed_btn{
    background-color: var(--main_color) !important;
    width: 20%;
    padding: 10px !important;
    border-radius: 30px !important;
}
.recharge_btns_container{
    border-radius: 10px !important;  
}
.recharge_btns_container button {
    font-size: 50px;
    color: var(--black_color);
    border:0px !important;
    
}
div.recharge_btns_container .css-i4bv87-MuiSvgIcon-root{
    font-size: 60px !important;
    color: var(--black_color);
}

@media screen and (max-width:480px) {
    .recharge_btns_container{
        width: 47% !important;
    }
    .recharge_container{
        margin-top: 25px;
    }
    .recharge_btns_container button{
        font-size: 30px;
        min-height: 80px;
    }
    div.recharge_btns_container .css-i4bv87-MuiSvgIcon-root{
        font-size: 30px !important;
    }
    div.input_container{
        row-gap: 15px !important;
    }
    div.input_box, div.input_coupon {
        width: 100% !important;
    }
    .Proceed_btn{
        width: 60%;
    }
}
.home_carousel_bg {
  background: var(--main_color);
  padding: 1px 0px 0px 0px;
}
.slider-container {
  margin-top: 50px;
  width: 100%;
}
.home_agent_card_container {
  margin-top: 20px !important;
}
.head_container_in_let_make_new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
}

.Let_make_new_people {
  position: relative;
}

.campaign_container img {
  position: absolute;
  top: 10px;
  right: 0px;
}

.Card_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 45px;
}

.agents_card_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  margin-top: 100px;
}

.AppBenefits_container {
  background: #f4f5f9;
  padding: 60px 0px 30px 0px;
}

.AppBenefits_content_container {
  padding: 0px 0px 0px 30px;
}

.AppBenefits_content_container,
.AppBenefits_img_container {
  width: 50%;
}

.AppBenefits_content_container ul {
  padding: 0px;
  margin-top: 80px;
}

.AppBenefits_content_container ul li {
  line-height: 80px;
  grid-column-gap: 5px;
  row-gap: 20px;
  column-gap: 5px;
  width: 100%;
  font-size: 20px;
}
#root
  > div
  > div.Let_make_new_people
  > div.AppBenefits_container
  > div
  > div
  > div.AppBenefits_content_container
  > div
  > div
  > div
  > h3::before {
  top: -36px;
  left: -50px;
  width: 100%;
  height: 200px;
}
#root
  > div
  > div.Let_make_new_people
  > div:nth-child(2)
  > div.Agents_container.mt-3
  > div.head_container_in_let_make_new.container
  > div
  > div
  > div
  > h3::before {
  top: -50px;
  left: -32px;
  width: 100%;
  height: 200px;
}

#root
  > div
  > div.Let_make_new_people
  > div.head_container_in_let_make_new.container
  > div.heading_in_let_make
  > div
  > div
  > h3::before {
  top: -45px;
  left: -20px;
  width: 100%;
  height: 200px;
}
@media screen and (max-width: 480px) {
  .mt-100 {
    margin-top: 70px !important;
  }
  .slider-container {
    margin-top: 0px;
  }
  .agents_card_container {
    margin-top: 10px;
  }
  .home_agent_card_container .product-item {
    flex-wrap: wrap !important;
  }
  .home_agent_card_container .product-item,
  .home_agent_card_container .Agent_avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home_agent_card_container .product-item, 
  .home_agent_card_container .Agent_avatar,
  .home_agent_card_container .Agent_details {
    width: 100%;
  }
  .favourite {
    filter: invert(1);
    top: 10px;
    width: 10%;
    cursor: pointer;
    left: 10px;
}
 
.home_agent_card_container .Agent_avatar::before {
    left: 55px;
  }
  .terms_container,
  .privacy_container,
  .disclaimer_container {
    margin-top: 0px !important;
  }
  .chatnow_container .MuiGrid-root .css-lmqqt6 {
    flex-wrap: nowrap !important;
  }
  .chatnow_container .MuiGrid-root {
    flex-wrap: wrap !important;
  }
  .head_container_in_let_make_new {
    margin-top: 40px;
  }

  .heading_in_let_make {
    width: 60%;
  }

  .Heading_container {
    padding: 0px;
  }

  .Heading_container,
  .AppBenefits_content_container,
  .AppBenefits_img_container {
    width: 100%;
  }

  .card-title,
  .head_inner h3 {
    font-size: 14px !important;

  }
.head_inner h3 {
    text-align: left;
  }
  .head_inner h3 {
    padding-left: 10px;
  }
  .AppBenefits_content_container ul{
    padding: 0px 0px 0px 45px;
    margin-top: 50px;
  }

  .head_inner p {
    font-size: 12px;
    padding-left: 10px;
  }
  .AppBenefits_content_container{
    padding: 0px;
  }

  #root > div > div.Let_make_new_people > div.head_container_in_let_make_new.container > div.heading_in_let_make > div > div > h3::before, #root > div > div.Let_make_new_people > div.AppBenefits_container > div > div > div.AppBenefits_content_container > div > div > div > h3::before{
    background-size: 60% !important;
    top: -30px;
    left: 0px;
    width: 100%;
    height: 150px;
}
#root > div > div.Let_make_new_people > div:nth-child(2) > div.Agents_container.mt-3 > div.head_container_in_let_make_new.container > div > div > div > h3::before {
  background-size: 60%;
  top: -30px;
  left: -10px;
  width: 100%;
  height: 150px;
}
#Abouts > div.about_content_container.text-justify > div.mob_center > div > div > div > h3{
  text-align: center !important;
  margin-top: 20px;
}
#Abouts > div.about_content_container.text-justify > div.mob_center > div > div > div > h3::before {
  top: -30px !important;
  left: 50px !important;
  width: 100%;
  height: 150px !important;
  background-size: 60%;
}
  .campaign_container img {
    width: 35% !important;
  }

  .Card_container {
    column-gap: 0px;
    margin-top: 50px;
  }

  .Card_container1 {
    width: 33.33%;
  }

  div.border-right {
    border-right: 0px !important;
  }

  .Card_container1 img {
    width: 40px !important;
  }

  .Card_container1 .card-body {
    padding: 5px 0px;
  }

  .Card_container1 .card-body .card-title, .AppBenefits_content_container ul li {
    font-size: 12px !important;
  }

  .AppBenefits_Inner_container {
    flex-wrap: wrap;
  }

  .faqs_container {
    margin-top: 100px !important;
  }
}

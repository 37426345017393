.support_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.support_form_container{
    width: 90%;
    padding: 0px;
    border: 1px solid var(--border_color);
    box-shadow: 0px 0px 6px 2px var(--border_color);
    border-radius: 10px;
    margin-bottom: 50px;
    overflow: hidden;
}
.support_form_heading{
    margin-top: 50px;
    margin-bottom: 100px;
}
#root > div > div.support_container.container.mt-100 > div > div > div > div > h3::before{
    top: -61px;
    left: -20px;
    width: 100%;
    height: 200px;
}

@media screen and (max-width:480px) {

    .support_container{
        margin-top: 0px !important;
    }
    .css-18403gz>:not(style) {
        margin: 12px 0px!important;
        width: 100% !important;
        text-align: center;
    }
    .support_form_container{
        width: 100%;
        margin-bottom: 100px;
        flex-wrap: wrap;
    }
}
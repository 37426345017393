.Otp_verify_input{
    width: 50px;
    margin-right: 10px;
    text-align:center;
    padding:10px;
}
@media screen and (max-width:480px) {
    .Otp_verify_input{
        width: 40px !important;
        padding: 5px;
    }
}
.agent_container {
    border-bottom: 1px solid #eee;
}
.endBtn_for_chat {
    font-size: 12px;
    border: 1px solid red;
    padding: 5px 10px;
    border-radius: 30px;
    background-color: red;
    color: #fff;
}
.user_img_agent {
    border: 4px solid #8a25ef;
    border-radius: 50%;
}
aside.EmojiPickerReact {
    width: 91% !important;
    margin-bottom: 20px;
}
.chat-window-container_header{
    background: var(--main_color) !important;
    width: 100% !important;
}
.Heading_container{
    width: 400px;
    padding: 10px;
}
.Heading_container h3{
    font-weight: bolder;
    position: relative;
    
}
.Heading_container p {
    font-size: 20px;
}
.Heading_container h3::before{
    content: '';
    position: absolute;
    top: -25px;
    left: -20px;
    width: 100%;
    height: 350px;
    background-image: url('../../Assets/images/Ellipse\ 119.png');
    background-repeat: no-repeat;
}

.chat-message__item {
    display: flex;
}
.chat-message__item__timestamp {
    color: #666;
    font-size: 12px;
    margin-bottom: 3px;
}
.chat-message__item__text {
    background: #eee;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1.4;
    padding: 8px;
    max-width: 65%;
    position: relative;
    z-index: 0;
    text-align: left;
    word-wrap: break-word;
}
.chat-message__item__text::after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 12px solid #eee;
    position: absolute;
    bottom: -6px;
    left: -6px;
    transform: rotate(45deg);
    z-index: 100;
}
.chat-message.is-same-origin .chat-message__item {
    justify-content: flex-end;
}
.chat-message.is-same-origin .chat-message__item__timestamp {
    text-align: right;
}
.chat-message.is-same-origin .chat-message__item__text {
    background: #0074d9;
    color: #fff;
}
.chat-message.is-same-origin .chat-message__item__text::after {
    border-top-color: #0074d9;
    transform: rotate(-45deg);
    left: auto;
    right: -6px;
}

.login_form_container select{
    width: 20%;
    border: 0px;
    border-right: 1px solid var(--border_color);
    padding: 8px;
}
.border_form{
    border: 1px solid var(--border_color);
    border-radius: 10px;
    overflow: hidden;
}
.login_form_container input{
    width: 80%;
    padding: 8px;
    border: 0px;
}
.login_form_container input:focus, .login_form_container select:focus{
    box-shadow: none;
    outline: none;
}
.login_btn{
    width: 100% !important;
    border-radius: 10px !important;
    padding: 0px;
}
.title_head{
    font-size: 18px !important;
}
.login_sign_container .modal-header {
    background: var(--text_chat_btn_color);
}
.slider-container{
    overflow: hidden;
}
.slider-container .sliderItem:focus-visible {
    outline: 0px !important;
}

.sliderItem{
    padding: 0px 30px;
}
.slick-dots{
    position: static !important;
    padding-bottom: 10px !important;
}
.slick-dots li button:before{
    font-size: 14px !important;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: var(--text_chat_btn_color) !important;
}


@media screen and (max-width:480px) {
    .sliderItem{
        padding: 0px 3px;
    }
    .home_carousel_bg{
        padding: 10px 0px 0px 0px !important;
    }
}
.about_content_container {
    width: 50%;
}
#Abouts > div.about_content_container.text-justify > div.mob_center > div > div > div > h3::before{
    top: -65px;
    left: -28px;
    width: 100%;
    height: 200px;
}

@media screen and (max-width:480px) {

    .about_content_container,
    div.about_img_container {
        width: 100% !important;
        margin-top: 30px;
    }

    .about_img_container img.p-5 {
        padding: 10px !important;
    }

    .About_container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .mob_center {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    .about_content_container .heading_in_let_make, .AppBenefits_content_container .heading_in_let_make, .faqs_container .heading_in_let_make {
        text-align: center;
    }

    .about_content_container .heading_in_let_make .Heading_container h3::before,
    .AppBenefits_content_container .heading_in_let_make .Heading_container h3::before {
        left: 20px;
        background-size: 80%;
        top: -45px;
    }
    .faqs_container .heading_in_let_make .Heading_container h3{
        padding-left: 60px;
    }
    .faqs_container .heading_in_let_make .Heading_container h3::before {
        left: 35px !important;
        background-size: 60%;
        top: -30px !important;
        height: 120px;
    }

    .about_content_container p {
        text-align: justify;
        font-size: 12px;
    }

}
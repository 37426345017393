.profile_form_container{
    margin-top: 230px;
    margin-bottom: 30px;
}
.form_container{
    border: 1px solid var(--border_color);
    padding: 80px 10px 20px 10px;
    border-radius: 10px;
}
.UserAvatar_img{
    position: absolute !important;
    top: -70px !important;
    transform: translate(50% -50%) !important;
    border: 2px solid var(--text_chat_btn_color);
}
@media screen and (max-width:480px) {
    .profile_form_container{
        margin-top: 100px;
    }
    .Agents_Profile_container{
        width: 100% !important;
        margin: 16px 0px !important;
    }
    .profile_form_container .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 100%;
        margin: 15px 0px;
    }
}
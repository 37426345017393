.accordion-button:not(.collapsed){
    background-color: var(--white_color) !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item{
    border:0px !important;
    border-bottom: 1px solid var(--border_color) !important;
}
.accordion-header button {
    font-size: 20px;
}
.accordion-body{
    padding: 10px !important;
    box-shadow: 0px 0px 10px 2px var(--border_color);
    border-radius: 10px;
    font-size: 18px;
}
.accordion-collapse{
    padding: 20px;
}

.faqs_container{
    margin-top: 30px;
    margin-bottom: 50px;
}

.faqcontainerpage{
    margin-top: 170px !important;
}

#root > div > div.Let_make_new_people > div.container.faqs_container > div.mob_center > div > div > div > h3::before{
    top: -50px;
    left: -20px;
    width: 100%;
    height: 200px;
}
@media screen and (max-width:480px) {
    .accordion-item{
        border: 1px solid var(--border_color) !important;
        margin-top: 15px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 5px 2px var(--border_color);
    }
    .accordion-body{
        box-shadow: none !important;
        font-size: 12px;
        padding: 0px !important;
    }
    .faqs_container{
        margin-top: 25px !important;
        margin-bottom: 0px;
    }
    .accordion-header button{
        font-size: 13px;
    }
}
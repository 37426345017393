.category_btn  .MuiButtonBase-root{
    display:inline-block !important;
    min-height: auto !important;
    font-family:sans-serif;
    letter-spacing: 0px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    border: 1px solid var(--border_color) !important;
    margin-right: 20px !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
}
.category_btn .MuiButtonBase-root img{
    margin-right: 10px;
}
.category_btn .css-1aquho2-MuiTabs-indicator{
    display: none;
}
.category_btn .css-aym9vq-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper{
    margin-bottom: 0px !important;
}
.category_btn .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: var(--main_color) !important;
}
.chat-window {
    background: #fff;
    bottom: 0 !important;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0 !important;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    height: 100vh;
}
.mr-2{
    margin-right: 10px;
}
.online_dot, .offline_dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 8px;
}
.offline_dot{
    background-color: var(--offline_color);
}
.online_dot{
    background-color: var(--report_mob_bg_color);  
}
.chat-window__footer form {
    width: 100%;
    text-align: left;
    display: flex;
}
.chat-window__footer input {
    width: 91%;
    border-right: 0px;
    padding: 10px !important;
}
.bntGroup_container{
    position: absolute;
    right: 15px;
    bottom: 70px;
    width: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}
.chat-window.is-open {
    opacity: 1;
    pointer-events: auto;
}
.chat-window__header {
    align-items: center;
    background: #333;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
}
.chat-window__title {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
}
.chat-window__body {
    flex: 1;
    padding: 16px 90px 80px 20px;
    overflow: auto;
}
.chat-window__body > * + * {
    margin-top: 16px;
}
.chat-window__footer {
    /* align-items: flex-end;
    display: flex; */
    background: #fff;
    box-shadow: 0px 0px 10px 2px #eee;
    padding: 16px 16px 5px 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.chat-window__input {
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 30px;
    flex: 1;
    font-size: 15px;
    min-height: 44px;
    max-height: 125px;
    padding: 10px;
    resize: none;
    display: inline-block !important;
}
.is-invalid{
    border-color: #dc3545;
    color:#dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.is-invalid::placeholder,.errorMsg{
    color:#dc3545 !important;
}
.errorMsg{
    font-size: 12px;
    display: none;
}
.chat-window__send-btn {
    background: transparent !important;
    border: 0px;
    margin-left: 10px;
}
.chat-window__send-btn:disabled {
    background: #bbb;
}
.chat-window__close-btn {
    appearance: none;
    background: #fff;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    line-height: 0;
    overflow: hidden;
    padding: 0;
}
.chat-window__close-btn svg {
    fill: #333;
    height: 32px;
    width: 32px;
    transition: all 0.15s ease-in-out;
}
.chat-window__close-btn:focus > svg, .chat-window__close-btn:hover > svg {
    fill: #555;
}
.chat-window__close-btn:focus, .chat-window__send-btn:focus, .chat-window__input:focus {
    outline: 0;
    /* box-shadow: 0 0 0 3px #99ceff; */
}
@media (min-width: 576px) {
    .chat-window {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
   }
    .chat-window__body {
        /* flex: 350px 0 0; */
   }
    .chat-window--top-right {
        bottom: auto;
        left: auto;
        right: 16px;
        top: 16px;
   }
    .chat-window--top-left {
        bottom: auto;
        left: auto;
        left: 16px;
        top: 16px;
   }
    .chat-window--bottom-right {
        bottom: 16px;
        left: auto;
        right: 16px;
        top: auto;
   }
    .chat-window--bottom-left {
        bottom: 16px;
        left: 16px;
        right: auto;
        top: auto;
   }
}

.gift_container{
    /* border-top: 1px solid #eee; */
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
    column-gap: 10px;
    row-gap: 10px;
}
.giftRechargebtn{
    background-color: var(--main_color) !important;
    border-radius: 30px !important;
}
.giftSubmitbtn{
    border-color: var(--text_chat_btn_color) !important;
    border-radius: 30px !important;
    color: var(--black_color) !important;
}

.gift_item{
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px 2px #eee;
    cursor: pointer;
}
.gift_item p{
    margin: 0px;
    font-size: 12px
}
.bth_container_gift{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
}
.git_popup .modal-body{
    padding: 0px 20px 1px 20px !important;
    margin-top: 0px !important;
}
.btn-recharge_gift{
    border-color: #8A25EF !important;
    background: #8A25EF !important;
    color: #fff !important;

}
.btn-submit_btn{
    background: transparent !important;
    border-color: #FB4FFF !important;
}
.giftActive{
  background: var(--main_color) !important;
  color: var(--white_color) !important;  
}
@media screen and (max-width:480px) {
    div.chatinner_container1{
        width: 100% !important;
        height: 73vh;
        padding: 10px !important;
        position: relative !important;
    }
    .chat-window{
        height: auto !important;
    }
    .chat_window_msg_container{
        box-shadow: 0px 0px 10px 2px #eee;
        border-radius: 10px;
    }
    .chat_socket_body{
        margin-left: 0px !important;
        margin-top: 15px;
        border-radius: 10px;
    }
    /* .chat-window__footer{
        bottom: 170px;
        padding: 5px;
    } */
    .chat-window__body{
        padding: 15px !important;
    }
   .chat-window__footer aside.EmojiPickerReact {
        width: 100% !important;
        margin-bottom: 20px;
        position: absolute;
        top: -465px;
        left: 0px;
        z-index: 1;
    }
    .chat-window__footer form{
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        border-radius: 30px;
        padding: 0px 0px 0px 8px;
    }
    .chat-window__footer input{
        border: 0px !important;
    }
    .desk_top{
        display: none;
    }
    .chat-window-container_header-right{
        width: 20%;
    }
    .chat-window-container_header{
        background: var(--main_color);

    }
}
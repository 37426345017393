.Alet_container{
    position: fixed;
    top: 20px;
    right:20px;
    z-index: 1111;
}

@media screen and (max-width: 480px) {
    .Alet_container{
        position: fixed;
        top: 20px;
        right:20px;
        left: 10px;
        z-index: 1111;
    }  
}
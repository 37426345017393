.pageheader_container {
    /* position: relative; */
}
.mob_480{
    display: none;
}
.pageheader_top1 {
    width: 100%;
    background: var(--main_color);
    height: 100px;
}
#navbarScroll > div > a.p-0.nav-link.disabled{
    padding: 0px 30px 0px 0px !important;
}
.menu_container, .balance_container {
    width: 100%;
    position: relative;
}
.balance_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.available_balance {
    width: 40%;
    background: var(--white_color);
    grid-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-top: 20px;
    padding: 0px 0px 0px 10px;
}
.available_balance p {
    margin: 0px;
    width: 65%;
    font-size: 14px;
}
p.rechargeBtn {
    background: var(--text_chat_btn_color);
    width: 35%;
    padding: 15px;
    color: var(--white_color);
    cursor: pointer;
}
.AgentHead_container {
    background-image: url('../../Assets/images/Group\ 1238.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90%;
    width: 25%;
    height: 220px;
    position: absolute;
    top: -110px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.AgentHead_container p {
    font-size: 20px;
    font-weight: bolder;
    color: var(--white_color);
    width: 100px;
    letter-spacing: 2px;
}
.menu_container ul {
    width: 70%;
    border: 1px solid var(--border_color);
    padding: 15px;
    list-style: none;
    grid-column-gap: 30px;
    column-gap: 30px;
    grid-row-gap: 30px;
    row-gap: 30px;
    justify-content: center;
    background: var(--white_color) !important;
    border-radius: 10px;
    position: absolute;
    top: -20px;
    right: 20px;
    box-shadow: 0px 3px 10px 2px var(--border_color);
}
.dropdowan_btn_container button, .dropdowan_btn_container button.show {
    background: transparent !important;
    border: 0px;
    color: var(--black_color);
    font-size: 12px;
    padding: 0px;
}
.dropdown-item{
    font-size: 14px;
    font-weight: 600 !important;
}
.dropdowan_btn_container button:hover{
    color: var(--black_color);
}
.menu_container ul li {
    width: 21%;
    font-size: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    column-gap: 10px;
    cursor: pointer;
}

.mob_header_container{
    position: fixed;
    z-index: 2;
    top: 0px;
}
.mob_header_container,
.header_menu_container, 
.refund_contaoner{
    width: 100%;
    background: var(--white_color);
    height: 100vh;
}
.mob_header{
    background: var(--main_color);
    padding: 20px;
}

.closeBtn_img{
    top: 15px;
    right: 15px;
}
.mob_user_avatar{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.mob_header_body{
    background: var(--white_color);
    padding: 20px;
}
.navBar_ ul{
    height: 82vh;
    overflow-y: scroll;
    padding: 10px !important;
}
.navBar_122 ul{
    height: auto;
    overflow-y:auto;
    padding: 10px !important;
}
.navBar_122 ul li:first-child{
    margin-top: 50px;
}
.navBar_ .alert-dismissible {
   padding: 5px !important;
}
.navBar_ ul li{
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 2px var(--border_color);
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 20px;
}

/* Bottom header css  */
.bottom_header_container{
    position: fixed;
    bottom: 0px;
    z-index: 2;
    background: var(--main_color) !important;
    height: 70px !important;
    align-items: center;
}
.btn_container.Mui-selected{
    color: var(--white_color) !important;
    background: var(--text_chat_btn_color);
    flex-direction: row !important;
    border-radius: 30px;
    column-gap: 5px;
    align-items: center;
    height: 45px;
    font-weight: 600;
}

.mob_responsive_container{
    background: var(--main_color);
    padding: 10px;
    width: 100%;
}
@media screen and (max-width:480px) {
    .desk_top{
        display: none;
    }
    .mob_480{
        display: inline-flex !important;
    }
    .Language_item{
        border-bottom: 1px solid var(--border_color) !important;
    }
    .Language_item:last-child{
        border: none !important;
    }
    .available_balance{
        width: 100%;
        border: 1px solid var(--border_color);
    }
    p.rechargeBtn{
        width: 45%;
        cursor: pointer;
    }

}
.latscht_top_container {
    background-color: var(--main_color);
    padding: 27px 0px 0px 0px;
}
.footer-about-astrology_card {
    padding: 10px 50px;
    text-align: left;
}
.footer-about-astrology_card ul {
    list-style: none;
    padding: 0px;
}
.footer-about-astrology_card ul li {
    color: var(--white_color);
    display: inline-block;
    padding: 0px 10px;
    border-right: 1px solid var(--white_color);
    cursor: pointer;
    font-size: 18px;
}

#root > div > div.Let_make_new_people > div.desk_top > div > div.container > div > div.col-6.col-md-6.footer-about-astrology_card.mt-5 > ul.d-flex.column-gap-3.align-items-center > li:nth-child(1), #root > div > div.Let_make_new_people > div.desk_top > div > div.container > div > div.col-6.col-md-6.footer-about-astrology_card.mt-5 > ul.d-flex.column-gap-3.align-items-center > li:nth-child(2){
    border: 0px;
}
.footer-about-astrology_card ul li:hover {
    border-radius: 30px;
    color: var(--black_color);
}
.footer_bottom_line {
    background: var(--white_color);
}
.footer-about-astrology_card{
    padding: 0px 100px 0px 0px !important;
}
.footer-download{
    font-size: 20px;
}
@media screen and (max-width:480px) {
    .footer-about-astrology_card{
        width: 100% !important;
    }
}
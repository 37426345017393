.Recharge_tab button {
    width: 50% !important;
    max-width: 50%;
    font-weight: 600 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--white_color) !important;
    background: var(--main_color);
}
.css-1aquho2-MuiTabs-indicator{
    background-color: var(--main_color) !important;
}
.wallet_tab_container{
    margin-top: 180px;
}
.table_item_container{
    width: 100%;
    border: 1px solid  var(--border_color);
    box-shadow: 0px 0px 6px 2px var(--border_color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}
.table_icon{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    column-gap: 20px;
}
.service_type caption{
    width: 100% !important;
    display: inline-block;
    padding: 0px;
    font-size: 12px;
}

@media screen and (max-width:480px) {
    .wallet_tab_container{
        margin-top: 20px;
    }
    .css-13xfq8m-MuiTabPanel-root{
        padding: 24px 0px !important;
    }
    .table_icon{
        width: 65%;
    }
    .service_icon img{
        width: 45px;
    }
    .service_type h6, .table_content h6{
        font-size: 14px;
    }
}
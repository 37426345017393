:root{
  --main_color:#8A25EF;
  --white_color:#FFFFFF;
  --black_color:#000000;
  --text_caption_color:#727272;
  --text_bg_color:#F1ECEC;
  --text_red_color:#FF0000;
  --text_chat_btn_color:#FB4FFF;
  --border_color:#eee;
  --offline_color: red;
  --report_mob_bg_color: #5DC23F;
}

/* @font-face {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:"slnt" 0;
} */

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  font-size: 18px;
}
.navbar-nav a{
  padding: 10px 30px !important;
  display: flex;
  align-items: center;
}
nav.bg-body-tertiary{
  background-color: var(--main_color) !important;
  font-weight:600;
}
.nav-link{
  color:var(--text_bg_color) !important;
}

.Btn_bg{
  background: var(--text_chat_btn_color) !important;
  width: 150px;
  padding: 10px !important;
  border-radius: 30px !important;
  border: var(--text_chat_btn_color) !important;
}

.text-right{
  text-align: right !important;
}
.mt-100{
  margin-top: 100px !important;
}
.text-justify{
  text-align: justify !important;
}
.mob_480 {
  display: none !important;
}

div.css-16t5uud{
  width: 100% !important;
  max-width: 100% !important;
}
.chatinner_container1{
  height: 100vh;
}
.fs-12{
  font-size: 12px !important;
}

.Preferences_form_container {
  width: 60%;
  padding: 40px;
  box-shadow: 0px 0px 10px 2px #eee;
  margin-top: 150px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.radio-form {
  row-gap: 40px;
  padding: 20px 0px;
  margin-bottom: 20px;
}

.Preferences_container input[type=checkbox],
.Preferences_container input[type=radio] {
  width: 30px;
  height: 30px;
}

.Preferences_container label,
.language_container h5 {
  margin: 0px;
}

.radio-group {
  width: 33.33%;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.language_container h5 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 10px;
}

.css-14eyfr9-MuiSlider-thumb {
  background-color: #FB4FFF !important;
}

.language_container .MuiButtonBase-root {
  background-color: #8A25EF;
  width: 200px;
  border-radius: 30px !important;
  padding: 10px;
}
.langActive{
  background:var(--main_color) !important;
  color: var(--white_color) !important;
}
.language_form_container {
  list-style: none;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin-top: 40px;
}

.language_form_container li {
  width: 100px;
  padding: 6px 10px;
  background: #F1ECEC;
  border-radius: 30px;
}

@media screen and (max-width:480px) {
  .Preferences_container{
    padding: 20px;
  }
  .Preferences_form_container{
    width: 100%;
    margin-top: 10px;
    padding: 20px;
  }
  .language_form_container{
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 20px;
  }
  .css-5b0jga{
    padding: 0px !important;
  }
  .radio-group{
    width: 49.33% !important;
  }
  .language_form_container li{
    width: 46% !important;
  }
}

.agents_card_container{
    margin-top: 100px;
}
.Card_container1{
    width: 31.33%;
    display: flex;
    align-items: center;
}
div.border-right{
    border-right: 1px dashed var(--text_caption_color) !important;
}
.nth-child:last-child{
    border-right: 0px !important;
}
.Card_container1 img {
    width: 100px !important;
}

/* Agents Card Css */

.product-item, .Agent_avatar {
    width: 31%;
}
.product-item {
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eee;
    padding: 10px 12px;
    position: relative;
}
.favourite {
    filter: invert(1);
    top: 10px;
    width: 6%;
    cursor: pointer;
}
.Agent_avatar {
    position: relative;
}
.Agent_avatar::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100px;
    height: 100px;
    background-image: url('../../Assets/images/Group 347.png');
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.Agent_avatar .avater_img {
    border: 3px solid #8A25EF;
    border-radius: 50%;
}

.Agent_details {
    width: 65%;
    text-align: left;
    padding: 0px 0px 0px 20px;
    position: relative;
}
.greenDot_container {
    width: 100%;
}
.Agent_details h5 {
    font-weight: bold;
    font-size: 16px;
    margin: 0px;
}
.Agent_details p {
    font-size: 14px;
    color: #727272;
    margin: 0px;
}
.agentsItem{
    padding: 10px 10px !important;
    width: 100% !important;
}
.agentsItem .product-item{
    width: 100% !important;
}
.css-irm4sl-MuiGrid-root{
    flex-basis: auto !important;
}
.btnSpiner_container{
    background: var(--main_color) !important;
    width: 80px;
    color: var(--white_color) !important;
    border-color: var(--main_color);
    border-radius: 30px !important;
}
@media screen and (max-width:480px) {
    .agents_card_container{
        margin-top: 0px;
    }
    .slider-container{
        overflow: scroll !important;
        padding: 0px;
      }
      .Agent_details h5{
        font-size: 14px;
      }
      .slider-container .MuiGrid-root{
        flex-wrap: nowrap;
        width: fit-content;
      }
      .slider-container .MuiGrid-root .css-lmqqt6 {
        flex-wrap: wrap;
      }
      .product-item{
        width: 100% !important;
      }

}
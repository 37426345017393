.chat_container{
    width: 100%;
    position: relative;
}
.user_msg p{
    background-color: #eee;
    border: 1px solid #eee;
    padding: 10px;
    display: inline-block;
}
.chat_msg_container_modal{
    padding: 10px !important;
}